var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === 'login')?[_vm._v(" Нажимая на кнопку «Войти», "),_c('br'),_vm._v(" вы принимаете условия "),_c('br'),_c('span',{on:{"click":function($event){$event.preventDefault();return _vm.$bus.$emit(
          'change.layout',
          'terms',
          'api/v4/terms/user/',
          'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ'
        )}}},[_vm._v("Пользовательского соглашения")]),_vm._v(" и даёте согласие на "),_c('span',{on:{"click":function($event){$event.preventDefault();_vm.$bus.$emit(
          'change.layout',
          'terms',
          'api/v4/terms/private-policy/',
          'Политика конфиденциальности'.toUpperCase()
        )}}},[_vm._v(" обработку персональных данных,")]),_vm._v(" подтверждаете, что ознакомились с информацией и вам 18+. "),_c('span',{on:{"click":function($event){$event.preventDefault();return _vm.$bus.$emit(
        'change.layout',
        'terms',
        'api/v4/terms/agreement-18/',
        'Соглашение 18+'
      )}}},[_vm._v("Подробнее")])]:_vm._e(),(_vm.type === 'register')?[_vm._v(" Нажимая на кнопку «Зарегистрироваться» или «Войти», вы принимаете условия "),_c('br'),_c('span',{on:{"click":function($event){$event.preventDefault();return _vm.$bus.$emit(
          'change.layout',
          'terms',
          'api/v4/terms/user/',
          'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ'
        )}}},[_vm._v(" Пользовательского соглашения ")]),_vm._v(" и даёте согласие на "),_c('span',{on:{"click":function($event){$event.preventDefault();_vm.$bus.$emit(
          'change.layout',
          'terms',
          'api/v4/terms/private-policy/',
          'Политика конфиденциальности'.toUpperCase()
        )}}},[_vm._v("обработку персональных данных,")]),_vm._v(" подтверждаете, что ознакомились с информацией и вам 18+. "),_c('span',{on:{"click":function($event){$event.preventDefault();return _vm.$bus.$emit(
      'change.layout',
      'terms',
      'api/v4/terms/agreement-18/',
      'Соглашение 18+'
    )}}},[_vm._v(" Подробнее ")])]:_vm._e(),(_vm.type === 'free')?[_vm._v(" Нажимая на кнопку «Попробовать бесплатно» "),_c('br'),_vm._v(" вы принимаете условия "),_c('br'),_c('span',{on:{"click":function($event){$event.preventDefault();return _vm.$bus.$emit(
          'change.layout',
          'terms',
          'api/v4/terms/user/',
          'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ'
        )}}},[_vm._v("Пользовательского соглашения")]),_vm._v(" и даёте согласие на "),_c('span',{on:{"click":function($event){$event.preventDefault();_vm.$bus.$emit(
          'change.layout',
          'terms',
          'api/v4/terms/private-policy/',
          'Политика конфиденциальности'.toUpperCase()
        )}}},[_vm._v("обработку персональных данных.")])]:_vm._e(),(_vm.type === 'register-password')?[_vm._v(" Нажимая на кнопку «Зарегистрироваться» "),_c('br'),_vm._v(" вы принимаете условия "),_c('br'),_c('span',{on:{"click":function($event){$event.preventDefault();return _vm.$bus.$emit(
          'change.layout',
          'terms',
          'api/v4/terms/user/',
          'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ'
        )}}},[_vm._v("Пользовательского соглашения")]),_vm._v(" и даёте согласие на "),_c('span',{on:{"click":function($event){$event.preventDefault();_vm.$bus.$emit(
          'change.layout',
          'terms',
          'api/v4/terms/private-policy/',
          'Политика конфиденциальности'.toUpperCase()
        )}}},[_vm._v(" обработку персональных данных, ")]),_vm._v(" подтверждаете, что ознакомились с информацией и вам 18+. "),_c('span',{on:{"click":function($event){$event.preventDefault();return _vm.$bus.$emit(
      'change.layout',
      'terms',
      'api/v4/terms/agreement-18/',
      'Соглашение 18+'
    )}}},[_vm._v(" Подробнее ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }