<script>
import Container from '@/components/Container.vue';
import Terms from '@/views/Terms.vue';
import HeaderBase from "@/components/Header";
import HeaderMenu from "@/components/HeaderMenu";

export default {
    name: 'contacts-page',
    components: {HeaderMenu, HeaderBase, Terms, Container },
    head: () => ({
        title: 'Контакты | Онлайн-кинотеатр FLEX - фильмы, сериалы и мультфильмы в хорошем качестве HD и FullHD',
        meta: [
            {
                name: 'description',
                content: 'Контакты. Онлайн-кинотеатр FLEX - бесплатный просмотр фильмов онлайн по подписке в хорошем HD качестве. Смотри мировые новинки кино и сериалы - переведено и озвучено по заказу FLEX - без рекламы!'
            }
        ],
    }),
    data: () => ({
        title: 'Store',
        content: '',
    }),
    async created() {
        try {
            const {data: {results}} = await this.$axios.get('api/v4/site-elements/?key=store');
            console.log(results);
            const { text: content, title } = results[0];

            this.title = title;
            this.content = content;
        } catch (e) {
            console.log(e);
        }
    }
};
</script>

<template lang="pug">
	.serials-page
		header.serials-page__header
			HeaderBase
			HeaderMenu
		main.copyright__container.store
			.copyright__header {{title}}
			.copyright__text(style="word-break: break-all;" v-html="content")
		footer
			//DefaultFooter
			//ContactInfo(show="true")
</template>

<style lang="scss">
.store {
  width: 100%;
  padding: 50px 70px;
  @media(max-width: 600px) {
    padding: 20px 20px;
    width: auto;
  }
  @media(max-width: 350px) {
    padding: 20px 10px;
  }
}
</style>
